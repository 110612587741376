import React from 'react'
import Layout from '../components/Layout'
import { graphql} from 'gatsby'
import Recipeslist from '../components/Recipeslist'
import SEO from "../components/SEO"

const contact = ({data:{allContentfulBengaliPlate:{nodes:recipes}}}) => {
  
  return (
    <Layout>
    <SEO title="Contact"/>
     <main className='page'>
     <section className='contact-page'>
     <article className='contact-info'>
     <h3> Want To Get In Touch?</h3>
     <p>
      Please write us if you like our recipes.
      And also let us know which recipe you want to know.
     </p>


     </article>
     <article>
      <form className='form contact-form' action="https://formspree.io/f/xrgdpykb"
  method="POST">
       <div className='form-row'>
       <label htmlFor='name'>your name</label>
        <input type="text" name="name" id="name"/>
       </div>
       <div className='form-row'>
       <label htmlFor='email'>your email</label>
        <input type="text" name="email" id="email"/>
       </div>
       <div className='form-row'>
       <label htmlFor='message'>your message </label>
        <textarea name="massege" id= "message"></textarea>
       </div>
       <button type='submit' className='btn-block'>Submit</button>
      </form>
     </article>
     
     </section>
     <section className='featured-recipes'>
   <h5>Look At This Awsomesouce</h5>
   <Recipeslist recipes={recipes}/>
     </section>

     </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBengaliPlate(
      sort: {fields: title, order: ASC}
      filter: {feature: {eq: true}}
    ) {
      nodes {
        id
        title
        cookTime
        prepTime
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      totalCount
    }
  }
`

export default contact
